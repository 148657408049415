import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout, Hero, Container, Fade } from '~/components';
import { Headline } from '~/elements';
import { mq } from '~/Theme';
import { setGAOptout } from '~/util';

const Text = styled.div``;

const Content = styled(Container)`
  max-width: 960px;

  margin-bottom: 80px;
  padding: 0;

  ${mq.lessThan('lg')`
    margin-bottom: 60px;
  `}

  ${mq.lessThan('md')`
    margin-bottom: 40px;
  `}

  ${mq.lessThan('sm')`
    margin-bottom: 32px;
  `}

  p {
    margin: 0;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const PrivacyPolicyPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const handleOptOut = () => {
    setGAOptout();
    localStorage.setItem('cookieAllowed', 'false');
  };

  return (
    <Layout>
      <Helmet>
        <title>Privacy policy - {data.site.siteMetadata.title}</title>
      </Helmet>
      <Content>
        <Hero
          headline={
            <>
              <span>Privacy</span> <br />
              <span>policy</span>
            </>
          }
          smallBottom
        />
        <Fade>
          <Text>
            <p>
              Your privacy is important to us. It is Dona's policy to respect your privacy regarding any information we
              may collect from you across our website, https://dona.ai, and other sites we own and operate.
            </p>

            <p>
              We only ask for personal information when we truly need it to provide a service to you. We collect it by
              fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and
              how it will be used. We only retain collected information for as long as necessary to provide you with
              your requested service. What data we store, we’ll protect within commercially acceptable means to prevent
              loss and theft, as well as unauthorized access, disclosure, copying, use or modification. We don’t share
              any personally identifying information publicly or with third-parties, except when required to by law.
            </p>

            <p>
              Our website may link to external sites that are not operated by us. Please be aware that we have no
              control over the content and practices of these sites, and cannot accept responsibility or liability for
              their respective privacy policies. You are free to refuse our request for your personal information, with
              the understanding that we may be unable to provide you with some of your desired services. Your continued
              use of our website will be regarded as an acceptance of our practices around privacy and personal
              information. If you have any questions about how we handle user data and personal information, feel free
              to contact us.
            </p>

            <Headline type="h4">Cookies</Headline>
            <p>
              We use “cookies” to collect information about you and your activity across our site. A cookie is a small
              piece of data that our website stores on your computer, and accesses each time you visit, so we can
              understand how you use our site. This helps us serve you content based on preferences you have specified.
              Please refer to our Cookie Policy for more information.
            </p>

            <Headline type="h4">Limits of our policy</Headline>
            <p>
              Our website may link to external sites that are not operated by us. Please be aware that we have no
              control over the content and policies of those sites, and cannot accept responsibility or liability for
              their respective privacy practices.
            </p>

            <Headline type="h4">How you can reach us</Headline>
            <p>
              Have any questions, comments, or concerns about this privacy policy, your data, or your rights with
              respect to your information? Please get in touch by emailing us at hello@dona.ai and we’ll be happy to
              answer them!
            </p>
          </Text>
        </Fade>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicyPage;
